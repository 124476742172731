.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.delivery-modal {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 25px;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  width: 50%;
  outline: none !important;
  overflow-y: auto;
  max-height: 85vh;
}

.recommendation-modal {
  position: relative;
  background-color: white;
  padding: 10px;
  border-radius: 25px;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  width: 50%;
  max-height: 85vh;
  overflow-y: auto;
  outline: none !important;
}

.schedule-modal {
  position: relative;
  background-color: white;
  border-radius: 25px;
  top: 52%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  width: 50%; 
  outline: none !important;
}

.delivery-overlay {
  z-index: 100;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0, .7);
}

/*BIG SCREEN > LAPTOPS / DESKTOPS */
@media screen and (max-width: 1425px){
  .delivery-modal {
    width: 60%;
    top: 20em;
    max-height: 80vh;
    overflow-y: auto;
  }
  .schedule-modal {
    width: 60%;
    top: 20em;
    max-height: 80vh;
    overflow-y: auto;
  }
  .recommendation-modal {
    width: 60%;
    top: 20em;
    max-height: 80vh;
    overflow-y: auto;
  }
}

/* MEDIUM SCREEN > TABLETS */
@media screen and (min-width: 768px) and (max-width: 1024px){
  .delivery-modal {
    width: 90%;
    top: 50%;
  }

  .schedule-modal {
    top: 52%;
    width: 90%;
    max-height: 85vh;
    overflow-y: auto;
  }

  .recommendation-modal {
    top: 54%;
    width: 80%;
    max-height: 85vh;
    overflow-y: auto;
  }
}  

/* SMALL SCREEN > MOBILE */
@media screen and (max-width: 760px){
  .delivery-modal {
    top: 55%;
    width: 85%;
    max-height: 80vh;
    overflow-y: auto;
  }

  .recommendation-modal {
    top: 54%;
    width: 85%;
  }

  .schedule-modal {
    top: 54%;
    width: 85%;
    max-height: 85vh;
    overflow-y: auto;
  }
}

.ReactModal__Body--open {
  overflow: hidden; /* prevents background page from scrolling when the modal is open */
}